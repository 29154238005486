
  export const style={
    inpuStyle:{
      colorInput:"#2E63FB"
    },
    url:{
      urlClient:"https://demo.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/noz.png"
    },
    CurrencyName:{
      name:"Noz/Nozes"
    },
    teamName:{
      name:"demo"
    },
    hasToken: false,
    hostHeader: "demo"
  }
